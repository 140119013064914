import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from "../components/AutomotiveDispatcher/HeroSection";
import TestimonialsSection from "../components/TestimonialsSection";
import CallToAction from "../components/AutomotiveDispatcher/CallToAction";
import FeaturesSection from "../components/AutomotiveDispatcher/FeaturesSection";
import PricingPage from "./PricingPage";

const AutomotiveDispatcherLandingPage = () => {
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'page_type': 'automotive_dispatcher_landing',
            'industry': 'automotive_dispatcher'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>GarageHero - Sistema para Despachantes Automotivos</title>
                <meta name="description"
                      content="Simplifique e automatize a gestão de processos de despachantes automotivos com o GarageHero. Controle documentos, agendamentos e clientes de forma eficiente." />
                <meta property="og:title" content="GarageHero - Sistema para Despachantes Automotivos" />
                <meta property="og:description"
                      content="Sistema completo para gestão de despachantes automotivos. Organize processos, controle clientes e automatize tarefas com facilidade." />
                <meta property="og:url" content="https://despachante.garagehero.com" />
                <meta name="keywords"
                      content="sistema para despachantes, gestão de despachantes, software para documentos automotivos, automação de despachante, controle de processos automotivos" />
            </Helmet>
            <HeroSection />
            <FeaturesSection />
            <TestimonialsSection />
            <PricingPage />
            <CallToAction />
        </>
    );
};

export default AutomotiveDispatcherLandingPage;
